var all_items = document.querySelectorAll('.accordian__title');
var add_new = document.querySelectorAll('.add__btn');

Array.from(all_items).forEach(per_item => {
  per_item.addEventListener('click', function () {
    this.parentNode.classList.toggle("active");
  });
});

Array.from(add_new).forEach(per_item => {
  per_item.addEventListener('click', function () {
    this.parentNode.parentNode.parentNode.classList.toggle("add-new");
  });
});